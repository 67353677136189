<template>
  <button
    class="m-cta-button"
    :class="{outlined, ghost}"
    :style="{ '--base-size': baseSize, '--width': width }"
    :disabled="disabled"
    @click.prevent="$emit('ctaClick')"
  >
    <slot>{{ label }}</slot>
  </button>
</template>

<script>
import i18n from 'i18n'

export default {
  name: 'MOnDemandFooter',
  props: {
    label: {
      type: String,
      default: i18n.t('continue')
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    ghost: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '100%'
    },
    baseSize: {
      type: String,
      default: '16px'
    }

  }
}
</script>

<style lang="stylus">
.m-cta-button
  border-radius 8px
  height calc(var(--base-size) * 3)
  margin 6px
  width var(--width)
  min-width fit-content
  background-color var(--q-color-primary)
  color var(--q-color-contrast)
  text-transform capitalize
  border none
  cursor pointer
  font-size var(--base-size)
  font-family 'Inter', sans-serif
  font-style normal
  letter-spacing 0.1px
  font-weight 600
  &.outlined
    background-color var(--q-color-contrast)
    color var(--q-color-primary)
    border 1px solid var(--q-color-primary)
  &.ghost
    background-color white
    color var(--q-color-primary)
    border none
</style>
